<template>
  <section class="section">
    <div class="container">
      <div class="page-header">
        <h3>Price Calculation</h3>
        <div class="quick-summary">
          <span>Grand Total</span>
          <h3>{{ grandTotal }} <span>LAK</span></h3>
        </div>
        <button class="button primary" @click="$router.push('/place-order')">
          Create an order
        </button>
      </div>
      <hr class="hline" />
      <div class="columns">
        <div class="column is-8">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">How many post you planned to post?</h3>
              <button class="button primary" @click="AddPost">Add</button>
            </div>
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Number of Post</th>
                  <th class="is-sm text-right">Week</th>
                  <th class="is-md text-right">Points Used</th>
                  <th class="is-md text-right">Price (LAK)</th>
                  <th class="is-xs text-right">Option</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, index) in posts" :key="index">
                  <td>1 Job Post</td>
                  <td class="text-right">
                    <div class="select">
                      <select>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                      </select>
                    </div>
                  </td>
                  <td class="text-right">20</td>
                  <td class="text-right">200.000</td>
                  <td>
                    <div class="icon-group">
                      <span @click="DeletePost"
                        ><i class="fas fa-trash"></i
                      ></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="column">
          <div class="sticky">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">Summary</h3>
              </div>
              <ul>
                <li>
                  <span>Post</span>
                  <span>{{ posts }}</span>
                </li>
                <li>
                  <span>Week</span>
                  <span>{{ postWeek }}</span>
                </li>
                <li>
                  <span>Points</span>
                  <span>{{ pointsUsed }}</span>
                </li>
                <li>
                  <span>Amount</span>
                  <span>{{ amount }}</span>
                </li>
                <li>
                  <span>Discount</span>
                  <span>{{ discount }}</span>
                </li>
                <li>
                  <span>Sub Total</span>
                  <span>{{ subTotal }}</span>
                </li>
                <li>
                  <span>VAT</span>
                  <span>{{ Vat }}</span>
                </li>
                <li>
                  <h3>Grand Total</h3>
                  <h3>{{ grandTotal }}</h3>
                </li>
              </ul>
            </div>
            <!-- Box -->
            <div class="botton-group">
              <button
                class="button primary lg right"
                @click="$router.push('/place-order')"
              >
                Place an order
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    posts: 0,
    postWeek: 0,
    pointsUsed: 0,
    amount: 0,
    discount: 0,
    pointPerPost: 20,
    priceOfPoint: 10000,
    subTotal: 0,
    Vat: 0,
    grandTotal: 0,
  }),
  watch: {
    posts() {
      this.pointsUsed = this.posts * this.pointPerPost;
      this.amount = this.pointsUsed * this.priceOfPoint;
      this.subTotal = this.amount - this.discount;
      this.grandTotal = this.amount - this.discount;
      if (this.posts > 3) {
        this.discount = 150000;
      } else {
        this.discount = 0;
      }
    },
  },
  methods: {
    AddPost() {
      if (this.posts < 10) {
        this.posts++;
        this.postWeek++;
      } else {
        alert(
          "If you would like to post more than 10 Posts please contact out sale"
        );
      }
    },
    DeletePost() {
      this.posts--;
      this.postWeek--;
    },
  },
};
</script>

<style lang="scss" scoped>
.box-header {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .box-title {
    font-weight: 700;
    font-size: 20px;
    color: var(--text-color);
    margin-right: 10px;
  }
}

table.table {
  border-collapse: separate;
  border-spacing: 0 10px;
  thead {
    tr {
      th {
        background-color: #fff;
        padding: 0;
        font-weight: 500;
      }
    }
  }
  tbody {
    tr {
      box-shadow: 0 0 1px 1px var(--border-color);
      cursor: pointer;
      padding: 0;
      &:hover {
        td {
          background-color: var(--light-grey-color);
        }
      }
      td {
        border: none;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
.select:not(.is-multiple):not(.is-loading)::after {
  right: 0;
}
.select {
  border: none;
  height: auto;
  select {
    border: none;
    height: auto;
    padding-right: 15px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
    &:hover {
      border: none;
    }
  }
}
.sticky {
  position: sticky;
  top: 20px;
  .box {
    margin-bottom: 0;
    border: 3px solid var(--primary-color);
    border-radius: 10px 10px 0 10px;
  }
  button.button {
    border-radius: 0 0 10px 10px;
  }
}
li {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  padding: 8px 0;
  border-bottom: 1px solid var(--border-color);
  &:last-child {
    border-bottom: none;
  }
  span {
    font-weight: 700;
    color: var(--text-color);
    font-size: 16px;
    &:first-child {
      font-weight: 400;
    }
  }
  h3 {
    font-weight: 700;
    color: var(--primary-color);
  }
}
.quick-summary {
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  border-bottom: 2px solid var(--info-color);
  span {
    padding: 3px 10px;
    font-size: 14px;
  }
  h3 {
    margin: 0 10px;
    display: block;
    font-size: 24px;
    color: var(--primary-color);
    span {
      font-weight: 400;
      font-size: 14px;
      display: inline;
      background-color: transparent;
      color: var(--text-color);
      padding: 0;
    }
  }
}
</style>
